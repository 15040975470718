import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import FadeIn from "transitions/fade-in"

const ClientLogos = () => {
  const { wp } = useStaticQuery(graphql`
    query ClientLogos {
      wp {
        options {
          options {
            logos: clientLogos {
              image: logo {
                altText
                databaseId
                localFile {
                  publicURL
                }
              }
            }
            mobileLogos: clientLogosMobile {
              image: logo {
                databaseId
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  const { logos } = wp.options.options
  const { mobileLogos } = wp.options.options

  const [showAll, setShowAll] = useState(false)

  const showAllLogos = () => {
    setShowAll(true)
  }

  const mobileOrder = databaseId => {
    // Find index of databaseId in mobile version of the field
    const order = mobileLogos
      .map(function({ image }) {
        return image.databaseId
      })
      .indexOf(databaseId)
    return order >= 0 ? order : "last"
  }

  return (
    <section className="mb-20 sm:mb-24 lg:mb-40 client-logos">
      <div className="xs-container">
        <div>
          <div className={`client-logos__grid ${showAll ? "show-all" : ""}`}>
            {logos.map(
              ({ image }) =>
                image && (
                  <FadeIn
                    className={`order-${mobileOrder(
                      image.databaseId
                    )} sm:order-none`}
                    key={`client-logo-${image.databaseId}`}
                    delay={200}
                  >
                    <div className="flex self-center justify-center justify-self-center">
                      <img
                        draggable="false"
                        src={image.localFile.publicURL}
                        alt={image.altText}
                      />
                    </div>
                  </FadeIn>
                )
            )}
          </div>
          <div className={`client-logos__show-all mt-20 text-center`}>
            <button className="tg-underline" onClick={showAllLogos}>
              Load more
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClientLogos
