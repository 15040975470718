import React, { useRef } from "react"

const CardVideo = React.memo(({ video }) => {
  const videoEl = useRef(null)
  let intervalRewind = null

  const playBackwards = () => {
    if (!videoEl.current) return

    videoEl.current.pause()
    const fps = 30

    intervalRewind = setInterval(() => {
      if (
        !videoEl.current ||
        (videoEl.current && videoEl.current.currentTime == 0)
      ) {
        clearInterval(intervalRewind)
        videoEl.current && videoEl.current.pause()
      } else {
        videoEl.current.currentTime += -(1 / fps)
      }
    }, 1000 / fps)
  }

  return (
    <div className="absolute inset-0 z-10">
      <video
        ref={videoEl}
        muted
        onMouseOver={() => {
          clearInterval(intervalRewind)
          videoEl.current.play()
        }}
        onMouseOut={() => playBackwards()}
        className="h-full w-full object-cover"
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  )
})

export default CardVideo
