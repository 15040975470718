import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { graphikAsterisk } from "utils/formatting"
import FadeIn from "transitions/fade-in"

export default () => {
  const { wpPage } = useStaticQuery(
    graphql`
      query HomeHero {
        wpPage(databaseId: { eq: 2561 }) {
          home {
            hero {
              heading
            }
          }
        }
      }
    `
  )
  const { heading } = wpPage.home.hero

  return (
    <div className="c-hero">
      <div className="container">
        <div className="max-w-lg mx-auto">
          <FadeIn>
            <h1 className="font-serif text-center text-72px">
              <span
                dangerouslySetInnerHTML={{ __html: graphikAsterisk(heading) }}
              ></span>
            </h1>
          </FadeIn>
        </div>
      </div>
    </div>
  )
}
