import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import Hero from "components/home/hero"
import Work from "components/home/work"
import AboutUs from "components/home/about-us"
import ClientLogos from "components/client-logos"
import LatestNews from "components/home/latest-news"
import ArticlesMarquee from "components/flexible/articles-marquee"

const Home = React.memo(({ data: { wpPage } }) => {
  if (!wpPage) return null
  const { seo } = wpPage

  return (
    <Transition>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: "page--home",
        }}
      />
      <Hero />
      <Work />
      <AboutUs />
      <ClientLogos />
      <ArticlesMarquee {...wpPage.home.articlesMarquee} />
    </Transition>
  )
})

export const query = graphql`
  query Home {
    wpPage(databaseId: { eq: 2561 }) {
      ...SEO
      home {
        articlesMarquee {
          heading
          content
          items {
            ... on WpExternalArticle {
              ...ExternalArticle
            }
          }
        }
      }
    }
  }
`

export default Home
