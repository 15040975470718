import React, { useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Link from "gatsby-plugin-transition-link"
import { renderImage } from "utils/imageRender"
import commaList from "utils/commaList"
import CardVideo from "components/card-video"

const Work = () => {
  const { wpPage } = useStaticQuery(graphql`
    query HomeWork {
      wpPage(databaseId: { eq: 2561 }) {
        home {
          work {
            ... on WpCaseStudy {
              databaseId
              slug
              terms {
                nodes {
                  name
                }
              }
              title
              caseStudy {
                textColour
                tileHasVideo
                hero {
                  backgroundColour
                }
                tileVideo {
                  mediaItemUrl
                }
                tileThumbnail {
                  ...FluidHalfWidthImage
                }
              }
            }
          }
          workButton {
            ...Link
          }
        }
      }
    }
  `)
  const { work, workButton } = wpPage.home

  return (
    <section className="my-20 o-container md:my-34 md:mt-26">
      <div className="c-case-studies__grid">
        {work.map(item => (
          <Tile item={item} key={`home-work-${item.databaseId}`} />
        ))}
        <div className="c-case-studies__cta style-links">
          <Link
            className="leading-normal text-llama text-bluewood"
            to={workButton.url}
          >
            {workButton.title}
          </Link>
        </div>
      </div>
    </section>
  )
}

const Tile = React.memo(({ item }) => {
  const hasVideo = item.caseStudy.tileHasVideo
  const videoURL =
    hasVideo && item.caseStudy.tileVideo
      ? item.caseStudy.tileVideo.mediaItemUrl
      : null
  const observed = useRef(null)

  let state = {
    transitionType: "card",
    backgroundColour: item.caseStudy.hero.backgroundColour,
    card: null,
  }

  useEffect(() => {
    state.card = observed.current
  }, [observed])
  const termNames = item.terms.nodes.map(({ name }) => name)

  return (
    <div ref={observed} className="c-tile">
      <Link
        to={`/work/${item.slug}/`}
        className="c-tile__media"
        exit={{
          state,
          length: 2,
        }}
        entry={{
          length: 2,
          delay: 0.2,
        }}
      >
        <div className="c-tile__image">
          <div className="relative h-full">
            {renderImage(item.caseStudy.tileThumbnail)}
            {hasVideo && <CardVideo video={videoURL} />}
          </div>
        </div>
      </Link>
      <div
        className={`c-tile__content pointer-events-none text-${item.caseStudy.textColour}`}
      >
        <h2 className="mb-2 font-serif text-llama">{item.title}</h2>
        <div className="case-study__subtitle">
          <span className="case-study__services">{commaList(termNames)}</span>
          <span className="case-study__link-text">Explore project</span>
        </div>
      </div>
    </div>
  )
})

export default Work
