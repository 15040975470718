import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Link from "components/transition-link"
import getRelativeUrl from "utils/getRelativeUrl"
import FadeIn from "transitions/fade-in"

const About = () => {
  const { wpPage } = useStaticQuery(graphql`
    query HomeAboutUs {
      wpPage(databaseId: { eq: 2561 }) {
        home {
          aboutUs {
            content
            link {
              title
              url
            }
          }
        }
      }
    }
  `)
  const { content, link } = wpPage.home.aboutUs

  return (
    <section className="pb-2 my-26 sm:pb-0 o-container sm:my-40">
      <div className="max-w-2xl mx-auto text-center style-links">
        <FadeIn>
          <div
            className="mb-8 font-serif text-40px"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <Link className="text-base text-midnight" to={link.url}>
            {link.title}
          </Link>
        </FadeIn>
      </div>
    </section>
  )
}

export default About
