import React from "react"
import FadeIn from "transitions/fade-in"
import Marquee from "react-fast-marquee"

const ArticlesMarquee = ({ heading, content, items }) => {
  return (
    <section className="c-articles-marquee my-20 md:my-24 lg:my-40">
      <div className="container">
        {(heading || content) && (
          <ArticlesMarqueeHeader heading={heading} content={content} />
        )}
        <ArticlesMarqueeItems items={items} />
      </div>
    </section>
  )
}

const ArticlesMarqueeHeader = ({ heading, content }) => (
  <div className="mx-auto text-center md:px-7 max-w-1xl mb-12 md:mb-16">
    <div className="px-5 md:px-0">
      {heading && (
        <FadeIn delay="0">
          <h2 className="mb-8 font-serif text-38px">{heading}</h2>
        </FadeIn>
      )}
      {content && (
        <FadeIn delay="100">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </FadeIn>
      )}
    </div>
  </div>
)

const ArticlesMarqueeItems = ({ items }) => (
  <div className="relative marquee-gradient">
    <Marquee gradient={false} speed={40} spaceBetween={60}>
      {items.map(({ title, externalArticle }, i) => (
        <div
          key={i}
          className="marquee-item text-center block md:w-auto px-8 md:px-10"
          style={{ transform: "translateZ(0)" }}
        >
          <div className="flex flex-col justify-center marquee-logo mb-2 md:mb-4">
            <img src={externalArticle.logo.sourceUrl} alt="" />
          </div>
          <h4 class="marquee-heading text-24px">{title}</h4>
        </div>
      ))}
    </Marquee>
  </div>
)

export default ArticlesMarquee
